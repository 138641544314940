import { AutocompleteInput, Filter, TextInput, useGetList } from 'react-admin';
import React, { useEffect, useState } from 'react';

const ShareLogFilter = (props: any) => {
  const [accounts, setAccounts] = useState([]);
  const [apps, setApps] = useState([]);
  const [groups, setGroups] = useState([]);

  const accountsList: any = useGetList(
    'accounts',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const appsList: any = useGetList(
    'botApps',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const groupsList: any = useGetList(
    'groupsFilter',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (accountsList.data) {
      setAccounts(accountsList.data?.map((record: any) => ({ id: record.id, name: record.login })));
    }
  }, [accountsList.data]);

  useEffect(() => {
    if (appsList.data) {
      setApps(appsList.data?.map((record: any) => ({ id: record.id, name: record.name })));
    }
  }, [appsList.data]);

  useEffect(() => {
    if (groupsList.data) {
      setGroups(groupsList.data?.map((record: any) => ({ id: record.name, name: record.name })));
    }
  }, [groupsList.data]);

  return (
    <Filter {...props} >
      <TextInput placeholder='User' source='user' resettable alwaysOn />
      <TextInput placeholder='RK' source='rk' resettable alwaysOn />
      <AutocompleteInput
        placeholder='App'
        source='app'
        choices={apps}
        alwaysOn
      />
      <AutocompleteInput
        placeholder='Group'
        source='group'
        choices={groups}
        alwaysOn
      />
      <AutocompleteInput
        placeholder='Account'
        source='account'
        choices={accounts}
        alwaysOn
      />
    </Filter>
  )
};

export default ShareLogFilter;
