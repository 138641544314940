import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton, FunctionField, useTheme, ChipField,
} from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import ShareLogFilter from './ShareLogFilter';

const ShareLogList = (props: any) => {
  const [theme] = useTheme();

  return (
    <List
      {...props}
      filters={<ShareLogFilter />}
    >
      <Datagrid
        sx={{ overflowX: 'auto', maxWidth: '100%' }}
      >
        <TextField source='demoId' label='#' />
        <TextField source='senderUsername' label='User' />
        <TextField source='senderGroupName' label='Group' />
        <FunctionField
          label='Application'
          render={(record: any) => {
            return (
              <Link
                target='_blank'
                onClick={(event: any) => event.stopPropagation()}
                style={{
                  color: theme === 'dark' ? 'lightgrey' : 'blue',
                }}
                to={`https://mirapanelapps.com/#/apps/${record.appID}`}
              >
                {record.appName}
              </Link>);
          }}
        />
        <FunctionField
          label='RK'
          render={(record: any) => {
            const rkList = record.accID.map((id: string, index: number) => (
              <React.Fragment key={index}>
                {id}
                {index < record.accID.length - 1 && <br />}
              </React.Fragment>
            ));
            const truncatedText = `${record.accID[0]}`;

            return (
              <Tooltip title={<div style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>{rkList}</div>} placement="bottom-start">
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {truncatedText} {record.accID.length > 1 && `+${record.accID.length - 1}`}
                </div>
              </Tooltip>
            );
          }}
        />
        <FunctionField
          label='FB Account'
          render={(record: any) => {
            return (
              <Link
                target='_blank'
                onClick={(event: any) => event.stopPropagation()}
                style={{
                  color: theme === 'dark' ? 'lightgrey' : 'blue',
                }}
                to={`https://mirapanelapps.com/#/accounts/${record.fbAccount}`}
              >
                FB Account
              </Link>);
          }}
        />
        <FunctionField
          label='Status'
          render={(record: any) => {
            let color = 'primary';
            const status = record.status;
            const isErrorStatus = status.includes('Error');

            if (status === 'shared') {
              color = 'success';
            } else if (status.includes('Error')) {
              color = 'error';
            }

            return (
              <Tooltip
                title={<span style={{ fontSize: '16px' }}>{status}</span>}
              >
                <span>
                  {!isErrorStatus && (
                    <ChipField
                      color={color as 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'default' | undefined}
                      source='status'
                      size='small'
                    />
                  )}
                  {isErrorStatus && (
                    <Chip label="Error" color="error" />
                  )}
                </span>
              </Tooltip>
            );
          }}
        />
        <TextField source='method' />
        <TextField source='shareVariant' />
        <TextField source='shareTime' />
        {/*<TextField source='naming' />*/}
        {/*<TextField source='pixel' />*/}
        {/*<FunctionField*/}
        {/*  label='Pixel'*/}
        {/*  render={(record: any) => {*/}
        {/*    const truncatedText = record.pixel.substring(0, 20);*/}
        {/*    const fullText = record.pixel;*/}
        {/**/}
        {/*    return (*/}
        {/*      <Tooltip title={fullText}>*/}
        {/*        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>*/}
        {/*          {truncatedText} {truncatedText < fullText && '...'}*/}
        {/*        </div>*/}
        {/*      </Tooltip>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<FunctionField*/}
        {/*  label='Full link'*/}
        {/*  render={(record: any) => {*/}
        {/*    const truncatedText = record.fullLink.substring(0, 20);*/}
        {/*    const fullText = record.fullLink;*/}
        {/**/}
        {/*    return (*/}
        {/*      <Tooltip title={fullText}>*/}
        {/*        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>*/}
        {/*          {truncatedText} {truncatedText < fullText && '...'}*/}
        {/*        </div>*/}
        {/*      </Tooltip>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<TextField source='fullLink' />*/}
        {/*<TextField source='userName' label='User'/>*/}
        {/*<TextField source='team' label='Team'/>*/}
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  );
};

export default ShareLogList;
